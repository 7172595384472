import "../robots/Robots.scss";
import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { usePagination } from "../../hooks";
import {
  OrderDto,
  PaginatedListDtoOfRobotAdminListDto,
  RobotAdminFilterCriteria,
  RobotAdminFilteringDataDto,
  RobotDefaultConfigurationDto,
  RobotStatisticsDto,
  orderDtoDefault,
} from "../../models";
import { useSearchParams } from "react-router-dom";
import {
  getDefaultConfiguration,
  getFilteringData,
  getRobots,
} from "../robots/robots.service";
import { useTranslation } from "react-i18next";
import { ErrorHelper, ToastHelper } from "../../helpers";
import { DashboardItemsListDetail, DashboardRobotDetailsView } from "./robots";
import { DashboardRobotsListView } from "./robots/DashboardRobotsListView";
import { getRobotsStatisticsData } from "./Dashboard.service";
import { RobotsFilterModal } from "../robots/RobotsFilterModal";

export const DashboardRobotsView = (props: DashboardRobotsViewProps) => {
  const {
    refreshCounter,
    setRobotsStatisticsCreatedAt,
    filterButtonEventTrigger,
    searchText,
    toggleInvalidMissions,
  } = props;
  const [isLeftSideWorking, setIsLeftSideWorking] = useState(false);
  const [lastFilter, setLastFilter] = useState("--");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [page, setPage] = useState<
    PaginatedListDtoOfRobotAdminListDto | undefined
  >(undefined);
  const [filter, setFilter] = useState<RobotAdminFilterCriteria>({
    ...robotAdminFilterCriteriaDefault,
  });
  const [filteringData, setFilteringData] = useState<
    RobotAdminFilteringDataDto | undefined
  >(undefined);
  const [state, setState] = useState({ ...robotsStateDefault });
  const [robotDefaultConfiguration, setRobotDefaultConfiguration] = useState<
    RobotDefaultConfigurationDto | undefined
  >(undefined);

  const [robotsStatistics, setRobotsStatistics] = useState<
    RobotStatisticsDto[] | undefined
  >(undefined);

  const [searchParams] = useSearchParams();

  const {
    pageValues,
    itemsPerPageChange,
    totalItemsChange,
    goNext,
    goPrevious,
    setCurrentPage,
  } = usePagination();

  const { t } = useTranslation();

  useEffect(() => {
    const getRobotsStatistics = async () => {
      try {
        const result = await getRobotsStatisticsData(
          refreshCounter <= 0,
          toggleInvalidMissions,
        );
        //use statistics created at from first result
        if (result && result.length > 0) {
          setRobotsStatisticsCreatedAt(result[0].statisticsCreatedAt);
        }
        setRobotsStatistics(result); // when refresh button was clicked, do not use cache));
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    getDataForFiltering();
    getRobotDefaultConfiguration();
    getRobotsStatistics();
  }, [refreshCounter, setRobotsStatisticsCreatedAt, toggleInvalidMissions]);

  useEffect(() => {
    if (!page) return;

    if (page.items.some((x) => x.id === state.currentId)) return;

    setState({
      ...state,
      currentId: page.items.length === 0 ? undefined : page.items[0].id,
    });
  }, [page, state]);

  useEffect(() => {
    setCurrentPage(1);
    setFilter({ ...filter, searchText: searchText });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    const strFilter = JSON.stringify(filter);
    if (lastFilter == strFilter || isFirstTime) return;

    setLastFilter(strFilter);
    search(filter, totalItemsChange);
  }, [
    filter,
    lastFilter,
    isFirstTime,
    totalItemsChange,
    toggleInvalidMissions,
  ]);

  useEffect(() => {
    if (
      pageValues.currentPage === filter.page &&
      pageValues.itemsPerPage === filter.maxItemCount
    )
      return;

    setFilter({
      ...filter,
      page: pageValues.currentPage,
      maxItemCount: pageValues.itemsPerPage,
    });
  }, [pageValues, filter]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id && isFirstTime) {
      setFilter({
        ...filter,
        robotIds: [id],
      });
    }
    setIsFirstTime(false);
  }, [searchParams, isFirstTime, filter]);

  const getDataForFiltering = async () => {
    try {
      setFilteringData(await getFilteringData());
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const getRobotDefaultConfiguration = async () => {
    try {
      setRobotDefaultConfiguration(await getDefaultConfiguration());
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const search = async (
    f: RobotAdminFilterCriteria,
    ti: (num: number) => void,
  ) => {
    let itemsReceived = 0;
    let totalItems = 0;

    setIsLeftSideWorking(true);

    try {
      const newPage = await getRobots(f);

      itemsReceived = newPage.items.length;
      totalItems = newPage.totalItems;

      setPage(newPage);
      ti(newPage.totalItems);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsLeftSideWorking(false);

    return { itemsReceived, totalItems };
  };

  const dataOrderChange = (od: OrderDto) => {
    setState({ ...state, order: od });
    setFilter({
      ...filter,
      page: 1,
      orderBy: od.order,
    });
  };

  const currentIdChange = (id: string | undefined) => {
    if (state.currentId === id) return;
    setState({ ...state, currentId: id });
  };

  const setIsFilterModalOpen = (open: boolean) => {
    if (state.isFilterModalOpen === open) return;
    setState({ ...state, isFilterModalOpen: open });
  };

  const applyModalSelection = (selected: RobotAdminFilterCriteria) => {
    setIsFilterModalOpen(false);
    setFilter({
      ...filter,
      robotIds: selected.robotIds,
      robotNames: selected.robotNames,
      robotLocations: selected.robotLocations,
      antSerialNumbers: selected.antSerialNumbers,
      cleanfixSerialNumbers: selected.cleanfixSerialNumbers,
      licenses: selected.licenses,
      robotTypes: selected.robotTypes,
      tenants: selected.tenants,
      antVersions: selected.antVersions,
      hmiVersions: selected.hmiVersions,
      updaterVersions: selected.updaterVersions,
    });
    setCurrentPage(1);
  };

  const refreshList = async () => {
    await refreshFullList();
  };

  const refreshFullList = async () => {
    const { itemsReceived, totalItems } = await search(
      filter,
      totalItemsChange,
    );

    if (filter.page === 0 || itemsReceived > 0 || totalItems === 0) return;
    goPrevious();
  };

  useEffect(() => {
    if (filterButtonEventTrigger === undefined) return;
    setIsFilterModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterButtonEventTrigger]);

  return (
    <div className="dashboard-container">
      <div className="row">
        <div className="col-7 ps-3 mt-3 mb-3 dashboard-component-title ">
          {t("adminPanel.dashboard.robots.title")}
        </div>
        <div className="col-5 ps-3 mt-3 mb-3 dashboard-component-title ">
          Robot details
        </div>
      </div>

      <DashboardItemsListDetail
        paginator={{
          itemsPerPage: pageValues.itemsPerPage,
          currentPage: pageValues.currentPage,
          totalItems: pageValues.totalItems,
          onNextPage: () => goNext(),
          onPreviousPage: () => goPrevious(),
          onItemsPerPageChange: (x) => itemsPerPageChange(x),
        }}
        leftSide={{
          className: "robots-left-side",
          component: (
            <>
              <DashboardRobotsListView
                items={page?.items ?? []}
                itemsWithStatistic={robotsStatistics ?? []}
                dataOrder={state.order}
                onDataOrderChange={dataOrderChange}
                currentId={state.currentId}
                onCurrentIdChange={currentIdChange}
                filter={filter}
                isWorking={isLeftSideWorking}
                onBadgeChange={applyModalSelection}
                onRobotAdded={refreshList}
                onRefresh={refreshList}
                robotDefaultConfiguration={robotDefaultConfiguration}
              />
              {state.isFilterModalOpen && filteringData && (
                <RobotsFilterModal
                  onClose={() => setIsFilterModalOpen(false)}
                  filteringData={filteringData}
                  filter={filter}
                  onApply={applyModalSelection}
                />
              )}
            </>
          ),
        }}
        rightSide={{
          className: "robots-right-side",
          component: (
            <DashboardRobotDetailsView
              currentId={state.currentId}
              onRefreshList={refreshList}
              robotsStatistics={robotsStatistics}
              toggleInvalidMissions={toggleInvalidMissions}
            />
          ),
        }}
      />
    </div>
  );
};

const robotAdminFilterCriteriaDefault: RobotAdminFilterCriteria = {
  robotIds: [],
  searchText: undefined,
  robotNames: [],
  robotLocations: [],
  antSerialNumbers: [],
  cleanfixSerialNumbers: [],
  licenses: [],
  robotTypes: [],
  tenants: [],
  antVersions: [],
  hmiVersions: [],
  updaterVersions: [],
  page: 1,
  maxItemCount: 25,
  orderBy: "desc",
};

interface RobotsState {
  currentId: string | undefined;
  isFilterModalOpen: boolean;
  order: OrderDto;
}

const robotsStateDefault: RobotsState = {
  currentId: undefined,
  isFilterModalOpen: false,
  order: {
    ...orderDtoDefault,
    orderColumns: "cleanfixSerialNumber",
  },
};

interface DashboardRobotsViewProps {
  searchText: string;
  filterButtonEventTrigger: boolean | undefined;
  refreshCounter: number;
  setRobotsStatisticsCreatedAt: (date: Date) => void;
  toggleInvalidMissions: boolean;
}
